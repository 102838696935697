import * as React from "react"
import {graphql, useStaticQuery} from "gatsby";
import {Woningtype} from "../../pages/woningen";

export default () => {
    const data = useStaticQuery(graphql`
        query {
            allBouwnummer(filter: {bouwtype: {algemeen: {omschrijving: {in: ["Blok 4", "Blok 6"]}}}}) {
                nodes {
                    id
                    ...BouwnummerPrijslijst
                    ...BouwnummerArea
                }
            }
            allBouwtype(filter: {algemeen: {omschrijving: {in: ["Blok 4", "Blok 6"]}}}) {
                nodes {
                    id
                    ...BouwtypeHeader
                    ...BouwtypeSpecificaties
                }
            }
        }
    `)
    return (
        <Woningtype
            titel={'Blok 4 en 6'}
            bouwtypes={data.allBouwtype.nodes}
            text={'Blok 4 beslaat een fraai en karaktervol rijtje drie-onder-één-kapwoningen. In Blok 6 sieren straks 5 ruime rijwoningen. De in totaal 8 woningen variëren van 94 tot 108m2 woonoppervlak. Elke woning beschikt over een dakkapel aan de voorkant voor extra ruimte op de verdieping.'}
            bouwnummers={data.allBouwnummer.nodes}
            url={'/woningen/blok4-en-6'}
        />
    )
}