import * as React from "react"
import Container from "@mui/material/Container";
import {Box, Button, Divider, Grid} from "@mui/material";
import {GatsbyImage, StaticImage} from "gatsby-plugin-image";
import {sectionGridSpacing, sectionPadding} from "../theme";
import {Slogan} from "../components/typography";
import Typography from "@mui/material/Typography";
import {graphql, Link} from "gatsby";

import Situatie from "../components/situatie";
import Prijs from "../components/prijs";
import Bouwnummers from "../components/bouwnummers";
import Specificaties from "../components/bouwtype/specificaties";
import {Bouwnummer, Bouwtype} from "../graphqlTypes";
import Blok1En5 from "../components/woningen/blok1-en-5";
import Blok4En6 from "../components/woningen/blok4-en-6";
import Blok2En3 from "../components/woningen/blok2-en-3";

interface WoningtypeProps {
    titel: string
    bouwtypes: Bouwtype[]
    text: string
    bouwnummers: Bouwnummer[]
    url: string
}
export const Woningtype = ({titel, bouwtypes, text, bouwnummers, url}: WoningtypeProps) => {
    const prijsVanaf = Math.min(...bouwtypes.map((bouwtype: Bouwtype) => bouwtype.algemeen?.koopaanneemsomVanaf || 0))
    const image = bouwtypes[0].hoofdfoto?.localFile.childImageSharp.gatsbyImageData
    return (
        <Box
            component={'article'}
            marginBottom={5}
        >
            <Box
                marginBottom={5}
            >
                {image &&
                    <GatsbyImage
                        image={image}
                        alt={titel}
                    />
                }
            </Box>
            <Box
                maxWidth={294}
            >
                <Typography variant={'h3'}>
                    {titel}
                </Typography>
                <Typography paragraph>
                    {text}
                </Typography>
                <Divider
                    sx={{
                        marginY: 3
                    }}
                />
                <Box
                    marginY={1}
                    color={'primary.main'}
                >
                    <Specificaties bouwtypes={bouwtypes}/>
                </Box>
                <Divider
                    sx={{
                        marginY: 3
                    }}
                />
                <Typography
                    textTransform={'uppercase'}
                    color={'primary.main'}
                    fontWeight={'bold'}
                    sx={{
                        marginY: 3
                    }}
                >
                    v.a. <Prijs prijs={prijsVanaf}/> v.o.n.
                </Typography>
                <Button
                    component={Link}
                    to={url}
                    variant={'contained'}
                >
                    Bekijk woning
                </Button>
            </Box>
        </Box>
    )
}

interface Props {
    data: PageQueryData
}
export default ({data}: Props) => {
    return (
        <article>
            <StaticImage
                src={'../images/sfeer/1549280480242.jpg'}
                alt={'wandelen'}
                layout={'fullWidth'}
                aspectRatio={1370/405}
            />
            <Box
                paddingY={sectionPadding}
            >
                <Container>
                    <Grid
                        container
                        spacing={sectionGridSpacing}
                    >
                        <Grid item xs={12} md={5}>
                            <Box
                                marginBottom={2}
                            >
                                <Slogan>
                                    Een heerlijk nieuw begin
                                </Slogan>
                            </Box>
                            <Typography
                                variant={'h2'}
                            >
                                Ziet u zichzelf hier al wonen?
                            </Typography>
                            <Typography paragraph>
                                Ineens komt alles samen. De woningen verrijzen steen voor steen, de straat krijgt vorm en het groen groeit en bloeit. En dat alles tegen een setting van modern, rustig en stijlvol wonen.
                            </Typography>
                            <Typography paragraph>
                                In uw achterhoofd weet u dat u de juiste keuze gaat maken. Mede ingegeven door het fraaie straatbeeld, de woonmogelijkheden, de harmonie van de woonomgeving en het duurzame karakter ervan. Door gelijkgestemde medebuurtbewoners, maar vooral ook door de voorzieningen en de ruimte om u heen. Dát is het woongenot van Daverhof.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <StaticImage
                                src={'../images/sfeer/marissa-price-kvHP-ueoMNI-unsplash.jpg'}
                                alt={'dineren'}
                                layout={'fullWidth'}
                                aspectRatio={631/351}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box
                component={'section'}
                paddingY={sectionPadding}
            >
                <Container>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xs={12}
                            md={4}
                        >
                            <Blok1En5 />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={4}
                        >
                            <Blok2En3 />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={4}
                        >
                            <Blok4En6 />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box
                component={'section'}
                paddingY={sectionPadding}
            >
                <Container>
                    <Typography
                        variant={'h2'}
                        textAlign={'center'}
                    >
                        Situatie Daverhof
                    </Typography>
                    <Situatie />
                </Container>
            </Box>
        </article>
    )
}

interface PageQueryData {
    eenEnVijf: {
        nodes: Bouwtype[]
    }
    tweeEnDrie: {
        nodes: Bouwtype[]
    }
    vierEnZes: {
        nodes: Bouwtype[]
    }
}

export const query = graphql`
    query {
        eenEnVijf: allBouwtype(filter: {algemeen: {omschrijving: {in: ["Blok 1", "Blok 5"]}}}) {
            nodes {
                ...BouwtypeHome
                algemeen {
                    perceeloppervlakteTot
                    perceeloppervlakteVanaf
                }
            }
        }
        tweeEnDrie: allBouwtype(filter: {algemeen: {omschrijving: {in: ["Blok 2", "Blok 3"]}}}) {
            nodes {
                ...BouwtypeHome
                algemeen {
                    perceeloppervlakteTot
                    perceeloppervlakteVanaf
                }
            }
        }
        vierEnZes: allBouwtype(filter: {algemeen: {omschrijving: {in: ["Blok 4", "Blok 6"]}}}) {
            nodes {
                ...BouwtypeHome
                algemeen {
                    perceeloppervlakteTot
                    perceeloppervlakteVanaf
                }
            }
        }
    }
`
