import * as React from "react"
import {Bouwtype} from "../../graphqlTypes";
import Typography from "@mui/material/Typography";
import Oppervlakte from "../oppervlakte";
import {Box} from "@mui/material";
import {graphql} from "gatsby";
import Specificatie from "../specificatie";

interface Props {
    bouwtypes: Bouwtype[]
}
const Specificaties = ({bouwtypes}: Props) => {
    const woonOppervlakTot = Math.max(...bouwtypes.map((bouwtype: Bouwtype) => bouwtype.algemeen?.woonoppervlakteTot || 0))
    const woonOppervlakVanaf = Math.min(...bouwtypes.map((bouwtype: Bouwtype) => bouwtype.algemeen?.woonoppervlakteVanaf || 0))
    const perceelTot = Math.max(...bouwtypes.map((bouwtype: Bouwtype) => bouwtype.algemeen?.perceeloppervlakteTot || 0))
    const perceelVanaf = Math.min(...bouwtypes.map((bouwtype: Bouwtype) => bouwtype.algemeen?.perceeloppervlakteVanaf || 0))
    return (
        <>
            <Specificatie
                name={'Woonoppervlak'}
            >
                <Oppervlakte oppervlakte={`${woonOppervlakVanaf} - ${woonOppervlakTot}`}/>
            </Specificatie>
            <Specificatie name={'Perceel'}>
                <Oppervlakte oppervlakte={`${perceelVanaf} - ${perceelTot}`}/>
            </Specificatie>
        </>
    )
}

export const query = graphql`
    fragment BouwtypeSpecificaties on Bouwtype {
        algemeen {
            perceeloppervlakteTot
            perceeloppervlakteVanaf
            woonoppervlakteTot
            woonoppervlakteVanaf
        }
    }
`

export default Specificaties
