import * as React from "react"
import {graphql, useStaticQuery} from "gatsby";
import {Woningtype} from "../../pages/woningen";

export default () => {
    const data = useStaticQuery(graphql`
        query {
            allBouwnummer(filter: {bouwtype: {algemeen: {omschrijving: {in: ["Blok 1", "Blok 5"]}}}}) {
                nodes {
                    id
                    ...BouwnummerPrijslijst
                    ...BouwnummerArea
                }
            }
            allBouwtype(filter: {algemeen: {omschrijving: {in: ["Blok 1", "Blok 5"]}}}) {
                nodes {
                    id
                    ...BouwtypeHeader
                    ...BouwtypeSpecificaties
                }
            }
        }
    `)
    return (
        <Woningtype
            titel={'Blok 1 en 5'}
            bouwtypes={data.allBouwtype.nodes}
            text={'Blok 1 omvat royale drie-onder-één-kapwoningen. Blok 5 siert het woonplan met 5 rijwoningen. De in totaal 8 woningen variëren van 127 tot wel 131m2 woonoppervlak. Elke woning staat op een ruim perceel waarbij de tuinen van Blok 1 op het oosten zijn gericht en die van Blok 5 op het zuiden.'}
            bouwnummers={data.allBouwnummer.nodes}
            url={'/woningen/blok1-en-5'}
        />
    )
}