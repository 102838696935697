import * as React from "react"
import {graphql, useStaticQuery} from "gatsby";
import {Woningtype} from "../../pages/woningen";

export default () => {
    const data = useStaticQuery(graphql`
        query {
            allBouwnummer(filter: {bouwtype: {algemeen: {omschrijving: {in: ["Blok 2", "Blok 3"]}}}}) {
                nodes {
                    id
                    ...BouwnummerPrijslijst
                    ...BouwnummerArea
                }
            }
            allBouwtype(filter: {algemeen: {omschrijving: {in: ["Blok 2", "Blok 3"]}}}) {
                nodes {
                    id
                    ...BouwtypeHeader
                    ...BouwtypeSpecificaties
                }
            }
        }
    `)
    return (
        <Woningtype
            titel={'Blok 2 en 3'}
            bouwtypes={data.allBouwtype.nodes}
            text={'Blok 2 en 3 zijn beide twee-onder-één-kapwoningen en zijn bestempeld als levensloopbestendige woningen. Het woonoppervlak varieert van 104 tot en met 118 m2. De bouwnummers 4 en 7 beschikken over een topgevel en zijn voorzien van een eigen parkeerplaats.'}
            bouwnummers={data.allBouwnummer.nodes}
            url={'/woningen/blok2-en-3'}
        />
    )
}